import React from 'react'
import { graphql } from 'gatsby'
import Typography from '@mui/material/Typography'
import type { Theme } from '@mui/material'
import { createStyles, styled } from '@mui/styles'
import Page from 'components/Page'
import Link from 'components/common/Link'
import { DefaultMeta } from 'components/SEO'

export interface Tag {
  fields: {
    slug: string
    date: string
  }
  frontmatter: {
    title: string
    date: string
  }
}

export interface TagTemplateProps {
  data: {
    tags: {
      totalCount: number
      edges: Array<{
        node: Tag
      }>
    }
  }
  pageContext: {
    tag: string
  }
}

const Root = styled('div')({
  width: '100%',
})

const TagLabel = styled(Typography)(
  createStyles(({ theme }: { theme: Theme }) => ({
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
  })),
)

function TagTemplate({ data: { tags }, pageContext }: TagTemplateProps) {
  const header = `${tags.totalCount} post${
    tags.totalCount === 1 ? '' : 's'
  } tagged with "${pageContext.tag}"`

  return (
    <>
      <DefaultMeta />
      <Page title="Tags" subtitle={header}>
        <Root>
          {tags.edges.map(({ node }: { node: Tag }) => (
            <TagLabel key={node.fields.slug}>
              {'\u2022'}{' '}
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link> -{' '}
              {node.fields.date}
            </TagLabel>
          ))}
        </Root>
      </Page>
    </>
  )
}

export const query = graphql`
  query ($tag: String) {
    tags: allMarkdownRemark(
      limit: 2000
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date(formatString: "MMMM Do, YYYY")
          }
          frontmatter {
            title
            date
          }
        }
      }
    }
  }
`

export default TagTemplate
